import React from 'react';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames/bind';

import styles from './ResetPasswordSent.module.scss';

export default function ResetPasswordSent() {
  return (
    <div className={styles.content}>
      <p className={text_styles.h1_bright_bold_centered}>Vielen Dank!</p>
      <p
        className={classNames(
          text_styles.body1_bright_centered,
          styles.success_message,
        )}>
        Falls die von Ihnen angegebene E-Mail-Adresse in unserem System
        registriert ist, erhalten Sie in Kürze eine E-Mail mit der Möglichkeit,
        ein neues Passwort zu erstellen.
        <br />
        <br />
        Bitte überprüfen Sie Ihren Posteingang sowie gegebenenfalls Ihren
        Spam-Ordner. Sollten Sie keine E-Mail erhalten, stellen Sie bitte
        sicher, dass Sie die korrekte E-Mail-Adresse für Ihren Getmomo-Zugang
        angegeben haben.
      </p>
    </div>
  );
}
