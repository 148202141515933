import {useCallback, useRef} from 'react';
import {Button} from '@get-momo/ui';

import styles from '../styles/FilterBar.module.scss';

import {GenericFilterChild} from './GenericFilterChild.js';

/**
 * FilterBar
 *
 * @param {object} props
 * @param {Array<object>} [props.filterConfigs=[]] - An array of filter definitions
 * @param {function} [props.onFilterChange] - Callback receiving the final query object
 * @param {string} [props.filterOperator='$and'] - "$and" or "$or"
 * @returns {JSX.Element}
 */
export function FilterBar(props) {
  const {filterConfigs = [], onFilterChange, filterOperator = '$and'} = props;

  const partialConditionsRef = useRef([]);
  const resetFnsRef = useRef([]);

  const mergeConditionsAndNotify = useCallback(() => {
    const validConditions = partialConditionsRef.current.filter(Boolean).flat();

    // If no active conditions, return empty object
    const finalQuery =
      validConditions.length === 0
        ? null
        : {
            op: filterOperator === '$or' ? '$or' : '$and',
            conditions: validConditions,
          };

    if (onFilterChange) {
      onFilterChange(finalQuery);
    }
  }, [filterOperator, onFilterChange]);

  /**
   * Called by each child when its partial condition changes
   *
   * @param {number} index
   * @param {object|null} partialCondition
   */
  function handlePartialConditionChange(index, partialCondition) {
    partialConditionsRef.current[index] = partialCondition;
    mergeConditionsAndNotify();
  }

  /**
   * Called by each child so we can store its reset function
   *
   * @param {number} index
   * @param {function} resetFn
   */
  function registerResetFunction(index, resetFn) {
    resetFnsRef.current[index] = resetFn;
  }

  // Reset all filters to their default states.
  function handleResetAll() {
    for (const resetFn of resetFnsRef.current) {
      if (resetFn) {
        resetFn();
      }
    }
  }

  const anyFilterActive = partialConditionsRef.current.some((cond) => {
    return cond !== null;
  });

  return (
    <div className={styles.filter_bar}>
      {filterConfigs.map((config, idx) => {
        return (
          <GenericFilterChild
            key={idx}
            config={config}
            index={idx}
            onPartialConditionChange={(partial) => {
              handlePartialConditionChange(idx, partial);
            }}
            registerReset={registerResetFunction}
          />
        );
      })}

      {anyFilterActive && (
        <Button onClick={handleResetAll} variant="ghost" size="sm">
          Filter zurücksetzen
        </Button>
      )}
    </div>
  );
}
