import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {RPC} from 'shared/api.js';
import classNames from 'classnames';
import {centsToPrice, formatDate, formatPrice} from 'shared/utils.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import Button from 'shared-web/components/Button.js';
import {TYPES} from 'shared/constants.js';
import {Icon} from '@get-momo/ui';

import {
  confirmClose,
  handleError,
  openCashDepositTransactionsReport,
} from '../../effects.js';

import {ReactComponent as TransactionReportSvg} from './transaction_report.svg';
import styles from './CashDepositTransactionsDialog.module.scss';

const FAIL_REASON_MAP = Object.freeze({
  EXPIRED: 'Abgelaufen',
  CANCELED_BY_USER: 'Storniert',
});

export default function CashDepositTransactionsDialog({onHide, ...props}) {
  const [visible, setVisible] = useState(true);
  const {deposit_id} = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    RPC('getCashDepositTransactions', {deposit_id})
      .then(setData)
      .catch((err) => {
        handleError(err);
      });
  }, [deposit_id]);

  function onClose() {
    confirmClose(true);
    setVisible(false);
  }

  if (!data) return null;

  const {
    transactions,
    account_balance_cents,
    tenant_display_name,
    number_of_tenants,
    tenant_type,
    viban,
  } = data;

  const footer = (
    <div className={styles.footer}>
      <p className={styles.sum}>
        <span className={styles.text}>Summe: </span>
        <span className={styles.sum_amount}>
          {formatPrice(account_balance_cents || 0)}
        </span>
      </p>
      <Button
        title="PDF"
        onClick={() => openCashDepositTransactionsReport(deposit_id)}
      />
    </div>
  );

  return (
    <Dialog
      title="Transaktionen"
      show={visible}
      onHide={onClose}
      footer={footer}
      {...props}>
      <div className={styles.container}>
        <div className={styles.account_owners_section}>
          <p className={text_styles.caption_left}>
            Virtuelles Unterkonto: {viban} | {tenant_display_name}
            {tenant_type === TYPES.individual && number_of_tenants > 1 && (
              <span>&nbsp;+1</span>
            )}
          </p>
        </div>
        <div className={classNames(styles.table, text_styles.caption_left)}>
          <div className={styles.table_header}>
            <div className={styles.date}>Datum</div>
            <div className={styles.sender_receiver}>Sender / Empfänger</div>
            <div className={styles.remittance_information}>
              Verwendungszweck
            </div>
            <div className={styles.additional_icon} />
            <div className={styles.amount}>Betrag (€)</div>
          </div>
          {!transactions || transactions.length === 0 ? (
            <Placeholder />
          ) : (
            <div className={styles.table_body}>
              {transactions.map(
                (
                  {
                    date,
                    counterparty_name,
                    counterparty_iban,
                    remittance_information,
                    amount,
                    fail_reason,
                    is_unsigned,
                    is_pending,
                  },
                  i,
                ) => {
                  const status =
                    FAIL_REASON_MAP[fail_reason] ??
                    (is_pending ? 'Ausstehend' : null);

                  return (
                    <div className={styles.table_row} key={i}>
                      <div className={styles.date}>{formatDate(date)}</div>
                      <div className={styles.sender_receiver}>
                        <p className={styles.counterparty_name}>
                          {counterparty_name}
                        </p>
                        <p className={styles.counterparty_iban}>
                          {counterparty_iban}
                        </p>
                      </div>
                      <div className={styles.remittance_information}>
                        {remittance_information}
                      </div>
                      <div className={styles.additional_icon}>
                        {is_unsigned && <Icon name="ebicsSignature" />}
                      </div>
                      <div
                        className={classNames(
                          styles.amount,
                          fail_reason && styles.dim,
                        )}>
                        <div
                          className={classNames(
                            fail_reason
                              ? styles.failedAmount
                              : amount < 0 && styles.payout,
                          )}>
                          {centsToPrice(amount)}
                        </div>
                        {status && (
                          <p className={styles.transaction_status}>{status}</p>
                        )}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}

function Placeholder() {
  return (
    <div className={styles.placeholder}>
      <TransactionReportSvg />
      <p>Es gibt noch keine Transaktionen.</p>
      <span>
        Sie können jedoch bereits eine PDF mit den Zahlungsinformationen
        erstellen.
      </span>
    </div>
  );
}
