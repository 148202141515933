import {useCallback, useEffect, useMemo, useState} from 'react';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';
import {Dropdown} from 'react-bootstrap';
import RadioButton from 'shared-web/components/RadioButton.js';

import {useFetchRefresh} from '../../hooks.js';
import Pagination from '../Pagination.js';
import {ReactComponent as EmptyTableIcon} from '../../assets/add_icon_skyline.svg';
import {ReactComponent as NoResultsTableIcon} from '../../assets/skyline.svg';
import Spinner from '../spinner/Spinner.js';
import {FilterBar} from '../../features/filters/components/FilterBar.js';

import {TableContext, useTableContext} from './context.js';
import {ReactComponent as ArrowSvg} from './arrow.svg';
import styles from './Table.module.scss';
import {useSorting} from './useSorting.js';

const DEFAULT_RESULTS_PER_PAGE = 10;
const ROW_HEIGHT = '78px';
const INITIAL_REFRESH_TRIGGER = 0;

/** @import {ColumnDef, TableAction} from './context.js' */

/**
 * @callback PlaceholderComponent
 * @param {object} props
 * @param {string} [props.search_query]
 * @param {boolean} props.loading
 * @returns {React.ReactNode}
 */

/**
 * Renders a GetMomo table
 * @param {object} props
 * @param {string} props.data_fetching_rpc_method_name The name of the RPC method in the backend to fetch the data from
 * @param {Array<ColumnDef>} props.columns The definition of the columns
 * @param {Record<string,TableAction>} [props.actions] The actions that can be performed on a data row
 * @param {string} [props.default_action] The default action (out of `actions`) to handle clicks on a row
 * @param {import('./useSorting.js').SortingSpec} [props.default_sorting] The default action to handle clicks on a row
 * @param {number} [props.results_per_page] The number of results per page
 * @param {number} [props.refresh_trigger] Changing this number will trigger a refresh
 * @param {string|React.ReactNode} [props.empty_table_label] - The label to display when the table is empty (i.e., no data and no search query).
 * @param {string|React.ReactNode} [props.no_match_label] - The label to display when no matches are found for the search query.
 *
 * @returns {React.ReactNode}
 */
export function Table({
  data_fetching_rpc_method_name,
  columns,
  actions,
  search_query,
  default_action,
  default_sorting,
  results_per_page = DEFAULT_RESULTS_PER_PAGE,
  refresh_trigger: outside_refresh_trigger = INITIAL_REFRESH_TRIGGER,
  reset_trigger,
  empty_table_label,
  no_match_label,
  filters = [],
}) {
  const [refresh, setRefresh] = useState(outside_refresh_trigger);
  const [filterQuery, setFilterQuery] = useState(null);

  useEffect(() => {
    if (outside_refresh_trigger === INITIAL_REFRESH_TRIGGER) return;
    setRefresh(Date.now());
  }, [outside_refresh_trigger]);
  const {sorting, toggleSorting, resetSorting, setAdvancedSorting} =
    useSorting(default_sorting);

  const {
    loading,
    rows: data,
    total,
    offset,
    setOffset,
  } = useFetchRefresh({
    method: data_fetching_rpc_method_name, // 'getPropertyOwners',
    search_query,
    per_page: results_per_page,
    refresh,
    sort: sorting,
    filter: filterQuery,
  });

  const {table_style} = useGenerateStyles({columns, data});

  useEffect(() => {
    resetSorting();
    setOffset(0);
  }, [reset_trigger, resetSorting, setOffset]);

  /**
   * @type {import('./context.js').TableContextValue}
   */
  const context_value = useMemo(
    () => ({
      columns,
      data,
      loading,
      sorting,
      actions,
      filterQuery,
      default_action,
      search_query,
      toggleSorting,
      resetSorting,
      setAdvancedSorting,
      setOffset,
    }),
    [
      columns,
      data,
      loading,
      sorting,
      actions,
      search_query,
      default_action,
      filterQuery,
      toggleSorting,
      resetSorting,
      setAdvancedSorting,
      setOffset,
    ],
  );

  return (
    <>
      <TableContext.Provider value={context_value}>
        <FilterBar filterConfigs={filters} onFilterChange={setFilterQuery} />

        <div
          role="table"
          className={styles.table}
          style={table_style}
          data-test="table">
          {columns.map((column_def, column_index) => (
            <HeaderCell key={column_def.name} column_index={column_index} />
          ))}
          {data?.length > 0 &&
            data.map((data_row, row_index) => (
              <TableRow
                key={row_index}
                data_row={data_row}
                row_index={row_index}
              />
            ))}
          <LoadingOverlay />
        </div>

        {data?.length === 0 && (
          <Placeholder
            search_query={search_query}
            filters={filterQuery}
            loading={loading}
            no_match_label={no_match_label}
            empty_table_label={empty_table_label}
          />
        )}

        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={results_per_page}
          />
        )}
      </TableContext.Provider>
    </>
  );
}

/**
 * Renders a GetMomo table
 * @param {object} props
 * @param {number} props.column_index The index of this column cell
 * @returns {React.ReactNode}
 */
function HeaderCell({column_index}) {
  const {columns, sorting, toggleSorting} = useTableContext();
  const column_def = useMemo(
    () => columns[column_index],
    [columns, column_index],
  );
  const {Header, sort, justifyContent} = column_def;
  const is_advanced_sort = Array.isArray(sort);

  const style = useMemo(() => {
    const grid_column_start = column_index + 1;
    let grid_column_end = grid_column_start + 1;
    for (let i = column_index + 1; i < columns.length; i++) {
      if (columns[i].Header === null) {
        grid_column_end++;
      } else {
        break;
      }
    }

    return {
      gridRow: `1`,
      gridColumn: `${grid_column_start} / ${grid_column_end}`,
      justifyContent,
    };
  }, [columns, column_index, justifyContent]);

  const handleClick = useCallback(
    (ev) => {
      if (!sort) return;
      ev.stopPropagation();
      toggleSorting(sort);
    },
    [toggleSorting, sort],
  );

  if (Header === null) return null;

  if (!sort) {
    return (
      <div
        role="columnheader"
        style={style}
        className={classNames(
          text_styles.caption_caps_reg_left,
          styles.header_cell,
          column_index === 0 && styles.row_start,
          column_index === columns.length - 1 && styles.row_end,
        )}>
        {typeof Header === 'function' ? <Header /> : Header}
      </div>
    );
  }

  if (is_advanced_sort) {
    return <HeaderSort column_index={column_index} style={style} />;
  }

  return (
    <button
      role="columnheader"
      style={style}
      className={classNames(
        text_styles.caption_caps_reg_left,
        styles.header_cell,
        column_index === 0 && styles.row_start,
        column_index === columns.length - 1 && styles.row_end,

        sort === sorting.column && styles.active,
        sorting?.direction === 'DESC' && styles.desc,
      )}
      onClick={handleClick}>
      {typeof Header === 'function' ? <Header /> : Header}
      <ArrowSvg />
    </button>
  );
}

function HeaderSort({column_index, style}) {
  const {columns, sorting, setAdvancedSorting} = useTableContext();
  const column_def = useMemo(
    () => columns[column_index],
    [columns, column_index],
  );
  const {sort} = column_def;

  const handleAdvancedSortClick = useCallback(
    ({column, direction}) => {
      setAdvancedSorting({column, direction});
    },
    [setAdvancedSorting],
  );

  return (
    <div role="columnheader" style={style}>
      <Dropdown title="Dropdown end">
        <Dropdown.Toggle as="button" className={styles.advanced_sorting_toggle}>
          <div
            style={style}
            className={classNames(
              text_styles.caption_caps_reg_left,
              styles.header_cell,
              column_index === 0 && styles.row_start,
              column_index === columns.length - 1 && styles.row_end,

              sort.some(({column}) => column === sorting.column) &&
                styles.active,
              sorting?.direction === 'DESC' && styles.desc,
            )}>
            {typeof columns[column_index].Header === 'function'
              ? columns[column_index].Header(sorting)
              : columns[column_index].Header}
            <ArrowSvg />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu align="start">
          <Dropdown.Header
            className={classNames(
              text_styles.caption_caps_reg_left,
              styles.dropdown_header,
            )}>
            Sortierung
          </Dropdown.Header>
          {sort.map(({label, column, direction}, index) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={() => handleAdvancedSortClick({column, direction})}
                className={styles.dropdown_item}>
                <RadioButton
                  checked={
                    sorting?.column === column &&
                    sorting?.direction === direction
                  }
                  label={label}
                  className={styles.radio_label}
                />
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

/**
 * Renders a single entry in data grid
 * @param {object} props
 * @param {object} props.data_row The data to be displayed
 * @param {number} props.row_index The the index of the row in the dataset array
 * @returns {React.ReactNode}
 */
function TableRow({data_row, row_index}) {
  const {columns} = useTableContext();
  return (
    <>
      {/* <div role="row" className={styles.table_row}></div> */}
      {columns.map((column, column_index) => (
        <GridCell
          key={`row-${row_index}_col-${column.name}-${column_index}`}
          data_row={data_row}
          row_index={row_index}
          column_index={column_index}
          total_columns={columns.length}
        />
      ))}
    </>
  );
}

/**
 * Renders a single cell in data grid
 * @param {object} props
 * @param {object} props.data_row The data to be displayed
 * @param {number} props.row_index The the index of the row in the dataset array
 * @param {number} props.column_index The the index of the column
 * @returns {React.ReactNode}
 */
function GridCell({data_row, row_index, column_index}) {
  const {columns, actions, default_action} = useTableContext();
  const {
    Cell,
    Header,
    accessor,
    justifyContent,
    name,
    action: column_override_action,
  } = columns[column_index];

  const style = useMemo(() => {
    const style = {
      gridRow: `${row_index + 2}`,
      gridColumn: `${column_index + 1}`,
      justifyContent,
    };
    if (Header === null) {
      style.paddingLeft = '0';
    }
    return style;
  }, [row_index, column_index, justifyContent, Header]);

  const handleClick = useCallback(
    (event) => {
      const action =
        column_override_action === undefined
          ? default_action
          : column_override_action;
      if (!action) return;
      event.stopPropagation();
      actions[action]?.(data_row);
    },
    [actions, column_override_action, data_row, default_action],
  );

  return (
    <div
      role="gridcell"
      style={style}
      onClick={handleClick}
      data-test="table-cell"
      className={classNames(
        text_styles.body2_left,
        styles.grid_cell,
        column_index === 0 && styles.row_start,
        column_index === columns.length - 1 && styles.row_end,
      )}>
      {Cell ? (
        <Cell value={accessor ? accessor(data_row) : data_row} />
      ) : accessor ? (
        accessor(data_row)
      ) : (
        data_row[name]
      )}
    </div>
  );
}

/**
 * Renders a loading spinner with a message indicating to the user that data is being loaded.
 *
 * @component
 * @returns {React.ReactNode} A loading spinner with a "please wait" message.
 */
function LoadingIndicator() {
  return (
    <div className={styles.loading_container}>
      <Spinner />
      <div className={classNames(text_styles.body2_centered, styles.text)}>
        Einen Moment bitte …
      </div>
    </div>
  );
}

/**
 * Renders the overlay indicating a loading state to the user.
 * The overlay will either span a minimum of 3 rows or the full table depending on the data length.
 *
 * @component
 * @returns {React.ReactNode|null} The loading overlay or null if not loading.
 */
function LoadingOverlay() {
  const {loading} = useTableContext();

  if (!loading) return null;

  return (
    <>
      <div className={styles.header_overlay} />
      <div className={styles.loading_overlay} role="alert" aria-busy="true">
        <LoadingIndicator />
      </div>
    </>
  );
}

/**
 * Generates dynamic styles for the table based on the column definitions
 * @param {object} props
 * @param {Array<ColumnDef>} props.columns The definition of the columns
 * @param {import('./context.js').TableData} props.data The definition of the columns
 * @returns {{table_style: object}} The various dynamic styles for the objects in the table
 */
function useGenerateStyles({columns, data}) {
  return useMemo(() => {
    const header_rows = 'auto';
    const no_data_content_num_rows = 1;
    return {
      table_style: {
        gridTemplateColumns: columns
          .map((column) => {
            if (column.width) {
              if (typeof column.width === 'number') {
                return `${column.width}px`;
              }
              return column.width;
            }
            return 'auto';
          })
          .join(' '),
        gridTemplateRows: `${header_rows} repeat(${
          data?.length || no_data_content_num_rows
        }, ${ROW_HEIGHT})`,
      },
    };
  }, [columns, data]);
}

/**
 * Renders a placeholder component when there is no data or no search results.
 * If the `loading` prop is true, it renders either a loading indicator or nothing.
 *
 * @param {object} props - The props for the placeholder component.
 * @param {string} [props.search_query] - The current search query, if applicable.
 * @param {boolean} props.loading - Indicates if the data is currently loading.
 * @param {string} props.label - The text label to display in the placeholder.
 * @returns {React.ReactNode} The rendered placeholder component or an empty fragment if loading.
 */
function Placeholder({
  search_query,
  filters,
  loading,
  empty_table_label,
  no_match_label,
}) {
  if (loading) return null;

  const hasFiltersOrSearchQuery =
    filters || (search_query && search_query.length > 0);
  const label = hasFiltersOrSearchQuery ? no_match_label : empty_table_label;
  const placeholderImage = hasFiltersOrSearchQuery ? (
    <NoResultsTableIcon alt={'No result found'} />
  ) : (
    <EmptyTableIcon alt={'No items added'} />
  );

  return (
    <div className={styles.placeholder_container}>
      {placeholderImage}
      <div className={classNames(text_styles.body2_centered, styles.text)}>
        {label}
      </div>
    </div>
  );
}
