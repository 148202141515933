import {FacetedFilter} from '@get-momo/ui';
import {useEffect, useMemo, useRef, useState} from 'react';
import isEqual from 'lodash/isEqual';

import {React18Wrapper} from '../../../components/React18Wrapper.js';

/**
 * BooleanCheckboxFilterChild
 *
 * @param {object} props
 * @param {string} [props.title] - Title for the UI
 * @param {string} [props.clearLabel] - Optional label for the clear button
 * @param {Array<{label:string, value:any}>} [props.options] - FacetedFilter options
 * @param {function} props.onPartialConditionChange - Notifies parent if condition changes
 * @param {function} props.registerReset - Parent callback to store reset
 * @param {number} props.index - Index for referencing this child
 * @returns {JSX.Element}
 */
export function BooleanCheckboxFilterChild(props) {
  const {
    title,
    options,
    onPartialConditionChange,
    clearLabel,
    registerReset,
    index,
  } = props;

  const [selectedSet, setSelectedSet] = useState(new Set());

  // Determine the column names from the selected values
  const columnNames = useMemo(() => {
    if (selectedSet.size === 0) {
      return [];
    }
    return Array.from(selectedSet).filter((val) => typeof val === 'string');
  }, [selectedSet]);

  // Build the partial condition or null if no selection
  const partialCondition = useMemo(() => {
    if (columnNames.length === 0) {
      return null;
    }
    return columnNames.map((column) => ({
      column,
      op: '$eq',
      value: true,
    }));
  }, [columnNames]);

  const lastConditionRef = useRef(null);
  const onPartialConditionChangeRef = useRef(onPartialConditionChange);
  onPartialConditionChangeRef.current = onPartialConditionChange;

  useEffect(() => {
    if (isEqual(lastConditionRef.current, partialCondition)) {
      return;
    }

    lastConditionRef.current = partialCondition;
    onPartialConditionChangeRef.current(partialCondition);
  }, [partialCondition]);

  function resetSingleSelect() {
    setSelectedSet(new Set());
  }

  const registerResetRef = useRef(resetSingleSelect);
  registerResetRef.current = registerReset;

  useEffect(() => {
    registerResetRef.current(index, resetSingleSelect);
  }, [index]);

  return (
    <React18Wrapper>
      <FacetedFilter
        title={title}
        options={options || []}
        selectedValues={selectedSet}
        onChange={setSelectedSet}
        clearLabel={clearLabel}
      />
    </React18Wrapper>
  );
}
