/**
 * This simple utility is a helper for feature flags.
 * Feature flag is a simple mechanism to hide a particular functionality behind a flag
 * so that it doesn't reach the users. It is especially useful for work in progress of
 * big features that may require multiple iterations before general availability.
 *
 * Feature flags should follow the "enable_" prefix convention to ensure a safe default state.
 *
 * - By default, a missing or undefined flag should result in the feature being OFF.
 * - Using `enable_` makes it explicit that `false` means the feature is disabled.
 * - This prevents unexpected behavior if a flag is accidentally omitted or not yet defined.
 * - Example:
 *     if (features.enable_new_dashboard) { ... }
 *   - If `enable_new_dashboard` is undefined, it evaluates as `false`, keeping the feature off.
 *
 * This approach makes feature rollouts safer and more predictable.
 */

const features = {
  manager_account_list_enabled:
    process.env.REACT_APP_MANAGER_ACCOUNT_LIST_ENABLED === 'true',
};

function assert(feature) {
  if (!Object.prototype.hasOwnProperty.call(features, feature)) {
    throw new Error(`"${feature}" is not a known feature`);
  }
}

export function isEnabled(feature) {
  assert(feature);
  return Boolean(features[feature]);
}

export function isDisabled(feature) {
  return !isEnabled(feature);
}

export default {
  isEnabled,
  isDisabled,
  features,
};
