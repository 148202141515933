import {MultiSelectFilterChild} from './MultiSelectFilterChild.js';
import {RangeFilterChild} from './RangeFilterChild.js';
import {BooleanCheckboxFilterChild} from './BooleanCheckboxFilterChild.js';

/**
 * GenericFilterChild
 *
 * Renders the appropriate child component based on filterType.
 *
 * @param {object} props
 * @param {object} props.config - Filter config object, e.g. { filterType:'range', column:'status'... }
 * @param {number} props.index - Position of this child in the parent's array
 * @param {function} props.onPartialConditionChange - Callback from parent
 * @param {function} props.registerReset - Callback from parent
 * @returns {JSX.Element | null}
 */
export function GenericFilterChild(props) {
  const {config, index, onPartialConditionChange, registerReset} = props;

  if (!config || !config.filterType) {
    return null;
  }

  switch (config.filterType) {
    case 'range': {
      return (
        <RangeFilterChild
          {...config}
          index={index}
          onPartialConditionChange={onPartialConditionChange}
          registerReset={registerReset}
        />
      );
    }
    case 'multi': {
      return (
        <MultiSelectFilterChild
          {...config}
          index={index}
          onPartialConditionChange={onPartialConditionChange}
          registerReset={registerReset}
        />
      );
    }
    case 'boolean': {
      return (
        <BooleanCheckboxFilterChild
          {...config}
          index={index}
          onPartialConditionChange={onPartialConditionChange}
          registerReset={registerReset}
        />
      );
    }
    default: {
      return null;
    }
  }
}
