import {FacetedFilter} from '@get-momo/ui';
import {useEffect, useMemo, useRef, useState} from 'react';
import isEqual from 'lodash/isEqual';

import {React18Wrapper} from '../../../components/React18Wrapper.js';

/**
 * MultiSelectFilterChild
 *
 * Allows multiple selections (stored in a Set). If no selections,
 * returns null to indicate no active filter condition.
 *
 * @param {object} props
 * @param {string} props.column - Name of the column for backend filtering
 * @param {string} [props.title] - Title for the UI
 * @param {string} [props.clearLabel] - Optional label for the clear button
 * @param {Array<{label:string, value:any}>} [props.options] - FacetedFilter options
 * @param {function} props.onPartialConditionChange - Reports condition or null to parent
 * @param {function} props.registerReset - Parent callback to store reset
 * @param {number} props.index - Index for referencing this child
 * @returns {JSX.Element}
 */
export function MultiSelectFilterChild(props) {
  const {
    column,
    title,
    clearLabel,
    options,
    onPartialConditionChange,
    registerReset,
    index,
  } = props;

  const [selectedValues, setSelectedValues] = useState(new Set());

  function handleFacetedChange(newSet) {
    setSelectedValues(newSet);
  }

  /**
   * Flatten array values into a single array, e.g.
   * if user selected something with value ["incomplete","rejected"]
   */
  const flatList = useMemo(() => {
    let result = [];
    for (const val of selectedValues) {
      if (Array.isArray(val)) {
        result = result.concat(val);
      } else {
        result.push(val);
      }
    }
    return result;
  }, [selectedValues]);

  /**
   * Build the partial condition or null if empty
   */
  const partialCondition = useMemo(() => {
    if (flatList.length === 0) {
      return null;
    }
    return {
      column,
      op: '$in',
      value: flatList,
    };
  }, [column, flatList]);

  const lastConditionRef = useRef(null);
  const onPartialConditionChangeRef = useRef(onPartialConditionChange);
  onPartialConditionChangeRef.current = onPartialConditionChange;

  useEffect(() => {
    if (isEqual(lastConditionRef.current, partialCondition)) {
      return;
    }

    lastConditionRef.current = partialCondition;
    onPartialConditionChangeRef.current(partialCondition);
  }, [partialCondition]);

  function resetMultiSelect() {
    setSelectedValues(new Set());
  }

  const registerResetRef = useRef(registerReset);
  registerResetRef.current = registerReset;

  useEffect(() => {
    registerResetRef.current(index, resetMultiSelect);
  }, [index]);

  return (
    <React18Wrapper>
      <FacetedFilter
        title={title}
        options={options || []}
        selectedValues={selectedValues}
        onChange={handleFacetedChange}
        clearLabel={clearLabel}
      />
    </React18Wrapper>
  );
}
