import React, {useEffect, useRef, useState} from 'react';
import Button from 'shared-web/components/Button.js';
import Input from 'shared-web/components/Input.js';
import {RPC} from 'shared/api.js';
import {useParams} from 'react-router-dom/cjs/react-router-dom.min.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {formatIban, formatPriceNoCurrencySymbol} from 'shared/utils.js';
import {useForm} from 'react-hook-form';

import {handleError} from '../../effects.js';
import Spinner from '../../components/spinner/Spinner.js';
import {useFetchRPC} from '../../hooks.js';

import {ReactComponent as DepositSvg} from './deposit.svg';
import styles from './DepositAccountDialog.module.scss';

export default function DepositAccountDialog({onExited}) {
  const {deposit_account_id} = useParams();
  const [visible, setVisible] = useState(true);
  const onExitedtRef = useRef(null);
  onExitedtRef.current = onExited;

  const {
    data: depositAccount,
    loading,
    error,
  } = useFetchRPC('getDepositAccount', {id: deposit_account_id});

  function onClose() {
    onExitedtRef.current();
    setVisible(false);
  }

  useEffect(() => {
    if (error) {
      onClose();
    }
  }, [error]);

  if (!visible) {
    return null;
  }

  return (
    <Dialog
      title={
        <div className={styles.dialogTitle}>
          <DepositSvg /> Kautionen SammelKonto
        </div>
      }
      onHide={onClose}
      show={visible}>
      {loading && <Spinner className={styles.spinner} />}
      {!loading && !error && (
        <>
          <AccountData depositAccount={depositAccount} />
          <Title depositAccount={depositAccount} />
        </>
      )}
    </Dialog>
  );
}

function AccountData({depositAccount}) {
  return (
    <>
      <div className={styles.accountData}>
        <div>
          <Input value={depositAccount?.name} label="Kontoname" readOnly />
          <Input
            value={formatIban(depositAccount?.iban)}
            label="IBAN"
            size={35}
            readOnly
          />
        </div>
        <div>
          <Input
            value={
              depositAccount?.balanceCents
                ? formatPriceNoCurrencySymbol(depositAccount?.balanceCents)
                : ''
            }
            label="Kontostand"
            className={styles.bold}
            readOnly
          />
          <Input value={depositAccount?.bic} label="BIC" readOnly />
        </div>
      </div>
      <div>
        <Input
          value={depositAccount?.activeDepositAccounts}
          label="Aktive Kautionskonten"
          size={23}
          readOnly
        />
      </div>
    </>
  );
}

function Title({depositAccount}) {
  const {
    register,
    reset,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const titleValue = watch('title', depositAccount?.title || '');

  const onSubmit = handleSubmit(async (fields) => {
    await updateDepositAccount({fields, depositAccount, setError, reset});
  });

  return (
    <div className={styles.title}>
      <div>
        <Input
          defaultValue={depositAccount?.title || ''}
          label="Titel"
          maxLength={100}
          {...register('title')}
          error={errors.title?.message}
        />
        <div className={styles.counter}>
          {`${titleValue.length} / 100 Zeichen`}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          title="Speichern"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      </div>
    </div>
  );
}

async function updateDepositAccount({fields, depositAccount, setError, reset}) {
  return RPC('updateDepositAccount', {
    id: depositAccount?.id,
    updateData: {
      title: fields.title,
    },
  })
    .then(() => {
      reset();
    })
    .catch((err) => {
      handleError(err);
      setError('title', {message: 'Fehler beim Speichern'});
    });
}
