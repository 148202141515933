import {RangeFilter} from '@get-momo/ui';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import {React18Wrapper} from '../../../components/React18Wrapper.js';

/**
 * RangeFilterChild
 *
 * @param {object} props
 * @param {string} props.column - Name of the column for backend filtering
 * @param {string} [props.clearLabel] - Optional label for the clear button
 * @param {number} props.minRange - Minimum slider value
 * @param {number} props.maxRange - Maximum slider value
 * @param {number} [props.step] - Slider step value
 * @param {function} [props.formatDisplayValue] - Optional function to format the displayed slider values
 * @param {function} [props.formatQueryValue] - Optional function to format the query values
 * @param {number} [props.initialMin] - Initial min selection (else defaults to minRange)
 * @param {number} [props.initialMax] - Initial max selection (else defaults to maxRange)
 * @param {string} [props.title] - Title for the UI
 * @param {boolean} [props.isDisabled] - Whether the filter is disabled
 * @param {function} props.onPartialConditionChange - Callback to send partial condition (or null) to parent
 * @param {function} props.registerReset - Parent callback to register a reset function
 * @param {number} props.index - Index used for referencing this child
 * @returns {JSX.Element}
 */
export function RangeFilterChild(props) {
  const {
    column,
    title,
    clearLabel,
    minRange,
    maxRange,
    step,
    isDisabled = false,
    formatDisplayValue,
    formatQueryValue,
    initialMin,
    initialMax,
    onPartialConditionChange,
    registerReset,
    index,
  } = props;

  const [values, setValues] = useState({
    min: initialMin == null ? minRange : initialMin,
    max: initialMax == null ? maxRange : initialMax,
  });

  const [queryValues, setQueryValues] = useState(values);

  // Debounce updates by 300ms
  const debouncedUpdate = useRef(
    debounce((newVals) => {
      setQueryValues(newVals);
    }, 300),
  ).current;

  const minRangeRef = useRef(minRange);
  minRangeRef.current = minRange;
  const maxRangeRef = useRef(maxRange);
  maxRangeRef.current = maxRange;
  const debouncedUpdateRef = useRef(debouncedUpdate);
  debouncedUpdateRef.current = debouncedUpdate;

  const handleRangeChange = useCallback((newVals) => {
    const minRange = minRangeRef.current;
    const maxRange = maxRangeRef.current;

    setValues(newVals);
    if (newVals.min === minRange && newVals.max === maxRange) {
      debouncedUpdateRef.current.cancel();
      setQueryValues({min: minRange, max: maxRange});
    } else {
      debouncedUpdateRef.current(newVals);
    }
  }, []);

  // UseMemo to build partial condition or null if at default state
  const columnRef = useRef(column);
  columnRef.current = column;
  const formatQueryValueRef = useRef(formatQueryValue);
  formatQueryValueRef.current = formatQueryValue;

  const partialCondition = useMemo(() => {
    const minRange = minRangeRef.current;
    const maxRange = maxRangeRef.current;
    const column = columnRef.current;
    const formatQueryValue = formatQueryValueRef.current;

    const atDefault =
      queryValues.min === minRange && queryValues.max === maxRange;
    if (atDefault) {
      return null;
    }
    return {
      column,
      op: '$between',
      value: [
        formatQueryValue ? formatQueryValue(queryValues.min) : queryValues.min,
        formatQueryValue ? formatQueryValue(queryValues.max) : queryValues.max,
      ],
    };
  }, [queryValues.min, queryValues.max]);

  // Compare the partial condition to the previous, only notify if changed
  const lastConditionRef = useRef(null);
  const onPartialConditionChangeRef = useRef(onPartialConditionChange);
  onPartialConditionChangeRef.current = onPartialConditionChange;

  useEffect(() => {
    if (isEqual(lastConditionRef.current, partialCondition)) {
      return;
    }

    lastConditionRef.current = partialCondition;
    onPartialConditionChangeRef.current(partialCondition);
  }, [partialCondition]);

  // Resets the slider back to default
  const resetRangeFilter = useCallback(() => {
    const minRange = minRangeRef.current;
    const maxRange = maxRangeRef.current;
    const debouncedUpdate = debouncedUpdateRef.current;

    const resetVals = {min: minRange, max: maxRange};

    debouncedUpdate.cancel();

    setValues(resetVals);
    setQueryValues(resetVals);

    onPartialConditionChangeRef.current(null);
  }, []);

  const registerResetRef = useRef(registerReset);
  registerResetRef.current = registerReset;

  const resetRangeFilterRef = useRef(resetRangeFilter);
  resetRangeFilterRef.current = resetRangeFilter;

  useEffect(() => {
    registerResetRef.current(index, resetRangeFilterRef.current);

    return () => {
      debouncedUpdateRef.current.cancel();
    };
  }, [index]);

  return (
    <React18Wrapper>
      <RangeFilter
        title={title}
        minRange={minRange}
        maxRange={maxRange}
        step={step}
        formatValue={formatDisplayValue}
        values={values}
        onChange={handleRangeChange}
        clearLabel={clearLabel}
        isDisabled={isDisabled}
      />
    </React18Wrapper>
  );
}
