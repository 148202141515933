import classNames from 'classnames';

import styles from './DepositStatusIcon.module.scss';
import {ReactComponent as IconCloseSvg} from './icon_close.svg';
import {ReactComponent as IconCheckSvg} from './icon_check.svg';

/**
 * Component to display a deposit status label with a colored circle and optional icons.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label text to display next to the status indicator.
 * @param {string} props.color - The color of the status indicator circle. Should match a predefined style (e.g., 'green', 'red', 'blue', 'silver').
 * @param {number | null} [props.balance_percentage] - Whether to display the balance percentage inside the status circle.
 * @param {boolean} [props.show_check_icon=false] - Whether to display a check icon inside the status circle.
 * @param {boolean} [props.show_close_icon=false] - Whether to display a close icon inside the status circle.
 *
 * @returns {JSX.Element} The rendered deposit status label component.
 */
export function DepositStatusIcon({
  color,
  show_check_icon = false,
  show_close_icon = false,
  label,
}) {
  return (
    <div className={styles.status}>
      <div
        className={classNames(styles.status_circle, styles[`status_${color}`])}>
        {label && <span>{label}</span>}
        {show_check_icon && <IconCheckSvg />}
        {show_close_icon && <IconCloseSvg />}
      </div>
    </div>
  );
}
