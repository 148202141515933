import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {formatDate, formatPriceNoCurrencySymbol} from 'shared/utils.js';
import {MANAGER_PATHS} from 'shared/constants.js';

import {showDialog} from '../../effects.js';
import {Table} from '../../components/Table/Table.js';

import {ReactComponent as CondominiumSvg} from './condominium.svg';
import {ReactComponent as DepositSvg} from './deposit.svg';
import styles from './AccountsTable.module.scss';

const PLACEHOLDER_TEXT_NONE_CREATED = (
  <>
    Sie haben noch keine Eigentümer angelegt.
    <br />
    Sie können Eigentümer per Klick auf „Eigentümer erstellen“ anlegen.
  </>
);

const PLACEHOLDER_TEXT_NO_MATCHES = (
  <>
    Leider konnten wir keine Ergebnisse finden.
    <br />
    Bitte überprüfen Sie Ihre Suchanfrage.
  </>
);

const DEFAULT_SORTING = {column: 'created_at', direction: 'DESC'};

export default function AccountsTable({
  search_query,
  reset_table_trigger,
  refresh,
}) {
  const actions = useAccountsTableActions();
  return (
    <Table
      data_fetching_rpc_method_name="getManagerAccountProjections"
      columns={columns}
      actions={actions}
      search_query={search_query}
      default_action="onOpenAccount"
      refresh_trigger={refresh}
      reset_trigger={reset_table_trigger}
      no_match_label={PLACEHOLDER_TEXT_NO_MATCHES}
      empty_table_label={PLACEHOLDER_TEXT_NONE_CREATED}
      default_sorting={DEFAULT_SORTING}
    />
  );
}

/**
 * @type {Array<import('../../components/Table/context.js').ColumnDef>}
 */
export const columns = [
  {
    name: 'konto',
    Header: 'Konto',
    accessor: (row) => <Konto row={row} />,
    sort: 'title',
  },
  {
    name: 'accountType',
    Header: 'Art',
    accessor: (row) => <AccountTypeIcon row={row} />,
    sort: 'firstAccountType',
  },
  {
    name: 'iban	',
    Header: 'Iban',
    accessor: (row) => <Iban row={row} />,
    sort: 'firstAccountIban',
  },
  {
    name: 'saldo',
    Header: 'Saldo',
    accessor: (row) => <Saldo row={row} />,
    sort: 'firstAccountBalanceCents',
  },
  {
    name: 'datum',
    Header: 'Datum',
    accessor: (row) => formatDate(row.createdAt),
    sort: 'createdAt',
  },
];

function Konto({row}) {
  return (
    <div className={styles.konto}>
      <div className={styles.kontoTitle}>{row.title}</div>
      <div className={styles.kontoSubtitle}>{row.subtitle}</div>
    </div>
  );
}

function AccountTypeIcon({row}) {
  return row.firstAccountType === 'CASH_DEPOSIT' ? (
    <DepositSvg />
  ) : (
    <CondominiumSvg />
  );
}

function Iban({row}) {
  return (
    <div>
      <div className={styles.iban}>
        {row.firstAccountIban}
        <AccountType accountType={row.firstAccountType} />
      </div>
      <div className={styles.iban}>
        {row.secondAccountId && row.secondAccountIban}
        <AccountType accountType={row.secondAccountType} />
      </div>
    </div>
  );
}

function AccountType({accountType}) {
  switch (accountType) {
    case 'CASH_DEPOSIT': {
      return null;
    }
    case 'CONDOMINIUM_FEE': {
      return <div className={styles.badge}>HAUSGELD</div>;
    }
    case 'CONDOMINIUM_RESERVE': {
      return <div className={styles.badge}>Rücklagen</div>;
    }
    default: {
      return '';
    }
  }
}

function Saldo({row}) {
  return (
    <div>
      <div className={styles.saldo}>
        {formatPriceNoCurrencySymbol(row.firstAccountBalanceCents)}
      </div>
      <div className={styles.saldo}>
        {row.secondAccountId && row.secondAccountBalanceCents
          ? formatPriceNoCurrencySymbol(row.secondAccountBalanceCents)
          : ''}
      </div>
    </div>
  );
}

export function useAccountsTableActions() {
  const history = useHistory();
  const actions = useMemo(
    () => ({
      onOpenAccount: (account) => {
        onOpenAccount(account, history);
      },
    }),
    [history],
  );

  return actions;
}

function onOpenAccount(account, history) {
  if (account?.is_manager) {
    showDialog('account_read_only', {account});
  } else {
    if (account.firstAccountType === 'CASH_DEPOSIT') {
      history.push(
        `${MANAGER_PATHS.AccountDepositDialog}/${account.firstAccountId}`,
      );
      return;
    }
    history.push(
      `${MANAGER_PATHS.AccountCondominiumDialog}/${account.firstAccountId}${
        account.secondAccountId ? `/${account.secondAccountId}` : ''
      }`,
    );
  }
}
