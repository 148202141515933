import React, {useMemo} from 'react';
import {Icon} from '@get-momo/ui';
import {
  DEPOSIT_STATUS_FILTERS,
  statusFilterToSubStatuses,
} from 'shared/constants.js';
import {centsToEuros, eurosToCents} from 'shared/utils.js';

import {DepositStatusIcon} from '../DepositStatusIcon.js';
import {useFetchRPC} from '../../../hooks.js';

const DEFAULT_MAX_DEPOSIT_AMOUNT_CENTS = 200_000;
const DEFAULT_MAX_ACCOUNT_BALANCE_CENTS = 200_000;

/**
 * Generates a list of filter definitions for deposits table.
 *
 * @param {boolean} [isCashDepositAccount=false] - Determines which filters to show
 * @returns {Array} An array of filter config objects for the FilterBar
 */
export function useDepositFilters(isCashDepositAccount = false) {
  const {data: maxDepositAmountCents, loading: loadingDepositAmount} =
    useFetchRPC('getMaxDepositByManager', {}, DEFAULT_MAX_DEPOSIT_AMOUNT_CENTS);

  const {data: maxAccountBalanceCents, loading: loadingAccountBalance} =
    useFetchRPC(
      'getMaxAccountBalanceByManager',
      {},
      DEFAULT_MAX_ACCOUNT_BALANCE_CENTS,
    );

  return useMemo(() => {
    if (loadingDepositAmount || loadingAccountBalance) {
      return [];
    }

    const statusFilter = {
      filterType: 'multi',
      column: 'status',
      title: 'status',
      clearLabel: 'Filter zurücksetzen',
      options: [
        {
          label: 'Unvollständig',
          value: statusFilterToSubStatuses[DEPOSIT_STATUS_FILTERS.incomplete],
          icon: <DepositStatusIcon color="orange" />,
        },
        {
          label: 'Vollständig',
          value: statusFilterToSubStatuses[DEPOSIT_STATUS_FILTERS.complete],
          icon: <DepositStatusIcon color="green" />,
        },
        {
          label: 'Teilausgezahlt',
          value:
            statusFilterToSubStatuses[
              DEPOSIT_STATUS_FILTERS.partially_paid_out
            ],
          icon: <DepositStatusIcon color="blue" />,
        },
        {
          label: 'Aufgelöst',
          value: statusFilterToSubStatuses[DEPOSIT_STATUS_FILTERS.rejected],
          icon: <DepositStatusIcon color="silver" />,
        },
        {
          label: 'Abgelehnt',
          value: statusFilterToSubStatuses[DEPOSIT_STATUS_FILTERS.closed],
          icon: <DepositStatusIcon color="red" />,
        },
      ],
    };

    const infosFilter = {
      filterType: 'boolean',
      title: 'infos',
      clearLabel: 'Filter zurücksetzen',
      options: [
        {
          label: 'EBICS ausstehend',
          value: 'has_unsigned_transactions',
          icon: <Icon name="ebicsSignature" />,
        },
      ],
    };

    const roundedUpMaxDepositAmountCents =
      Math.ceil(centsToEuros(maxDepositAmountCents) / 100) * 100;

    const depositAmountRangeFilter = {
      filterType: 'range',
      column: 'deposit_amount_cents',
      title: 'kautionshöhe',
      minRange: 0,
      maxRange: roundedUpMaxDepositAmountCents,
      isDisabled: roundedUpMaxDepositAmountCents === 0,
      step: 100,
      clearLabel: 'Filter zurücksetzen',
      formatDisplayValue: (val) => {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(val);
      },
      formatQueryValue: (val) => {
        return eurosToCents(val);
      },
    };

    const roundedUpMaxAccountBalanceCents =
      Math.ceil(centsToEuros(maxAccountBalanceCents) / 100) * 100;

    const accountBalanceRangeFilter = {
      filterType: 'range',
      column: 'account_balance_cents',
      title: 'Saldo',
      minRange: 0,
      maxRange: roundedUpMaxAccountBalanceCents,
      isDisabled: roundedUpMaxAccountBalanceCents === 0,
      step: 100,
      clearLabel: 'Filter zurücksetzen',
      formatDisplayValue: (val) => {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(val);
      },
      formatQueryValue: (val) => {
        return eurosToCents(val);
      },
    };

    const depositGuarantee = [
      statusFilter,
      depositAmountRangeFilter,
      accountBalanceRangeFilter,
    ];

    const cashDeposit = [
      statusFilter,
      infosFilter,
      depositAmountRangeFilter,
      accountBalanceRangeFilter,
    ];

    return isCashDepositAccount ? cashDeposit : depositGuarantee;
  }, [
    isCashDepositAccount,
    loadingAccountBalance,
    loadingDepositAmount,
    maxAccountBalanceCents,
    maxDepositAmountCents,
  ]);
}
