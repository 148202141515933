import React, {useEffect, useRef, useState} from 'react';
import Button from 'shared-web/components/Button.js';
import Input from 'shared-web/components/Input.js';
import {RPC} from 'shared/api.js';
import {useParams} from 'react-router-dom/cjs/react-router-dom.min.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import classNames from 'classnames';
import {formatIban, formatPriceNoCurrencySymbol} from 'shared/utils.js';
import {useForm} from 'react-hook-form';
import {CONDOMINIUM_TYPES} from 'shared/constants.js';

import {handleError} from '../../effects.js';
import Spinner from '../../components/spinner/Spinner.js';
import {useFetchRPC} from '../../hooks.js';

import {ReactComponent as CondominiumSvg} from './condominium.svg';
import styles from './CondominiumAccountDialog.module.scss';

export default function CondominiumAccountDialog({onExited}) {
  const {first_account_id, second_account_id} = useParams();
  const [visible, setVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('Hausgeld');
  const onExitedtRef = useRef(null);
  onExitedtRef.current = onExited;

  const {
    data: firstAccount,
    loading: firstAccountLoading,
    error: firstAccountError,
  } = useFetchRPC('getCondominiumAccount', {id: first_account_id});

  const {
    data: secondAccount,
    loading: secondAccountLoading,
    error: secondAccountError,
  } = useFetchRPC(
    'getCondominiumAccount',
    {id: second_account_id},
    null,
    !second_account_id,
  );

  const {
    data: condominium,
    loading: condominiumLoading,
    error: condominiumError,
    refetch: refetchCondominium,
  } = useFetchRPC('getCondominium', {id: first_account_id});

  const isLoading =
    firstAccountLoading || secondAccountLoading || condominiumLoading;

  const hasError = firstAccountError || secondAccountError || condominiumError;

  function onClose() {
    onExitedtRef.current();
    setVisible(false);
  }

  useEffect(() => {
    if (hasError) {
      onClose();
    }
  }, [hasError]);

  if (!visible) {
    return null;
  }

  return (
    <Dialog
      title={
        condominium?.title && (
          <div className={styles.title}>
            <CondominiumSvg /> {condominium?.title}
          </div>
        )
      }
      onHide={onClose}
      show={visible}
      className={styles.dialog}>
      {isLoading && <Spinner className={styles.spinner} />}
      {!isLoading && !hasError && (
        <>
          <div className={styles.tabs}>
            {['Hausgeld', 'Rücklageb', 'Basisangaben'].map((tab) => (
              <button
                key={tab}
                className={classNames(styles.tab, {
                  [styles.activeTab]: activeTab === tab,
                })}
                onClick={() => setActiveTab(tab)}>
                {tab}
              </button>
            ))}
          </div>
          <div className={styles.tabContent}>
            {activeTab === 'Hausgeld' && (
              <AccountData
                account={
                  firstAccount?.type === CONDOMINIUM_TYPES.CONDOMINIUM_FEE
                    ? firstAccount
                    : secondAccount
                }
                condominium={condominium}
              />
            )}
            {activeTab === 'Rücklageb' && (
              <AccountData
                account={
                  firstAccount?.type === CONDOMINIUM_TYPES.CONDOMINIUM_FEE
                    ? secondAccount
                    : firstAccount
                }
                condominium={condominium}
              />
            )}
            {activeTab === 'Basisangaben' && (
              <Basisangaben
                condominium={condominium}
                onSave={refetchCondominium}
              />
            )}
          </div>
        </>
      )}
    </Dialog>
  );
}

function AccountData({account, condominium}) {
  return (
    <>
      {account?.id ? (
        <div className={styles.accountData}>
          <div>
            <Input
              value={account?.id ? condominium?.legalName : ''}
              label="Kontoname"
              readOnly
            />
            <Input
              value={formatIban(account?.iban)}
              label="IBAN"
              size={35}
              readOnly
            />
          </div>
          <div>
            <Input
              value={
                account?.balanceCents
                  ? formatPriceNoCurrencySymbol(account?.balanceCents)
                  : ''
              }
              label="Saldo"
              className={styles.bold}
              readOnly
            />
            <Input value={account?.bic} label="BIC" readOnly />
          </div>
        </div>
      ) : (
        <EmptyAccountData type={condominium?.type} />
      )}
    </>
  );
}

function EmptyAccountData({type}) {
  return type === CONDOMINIUM_TYPES.CONDOMINIUM_FEE ? (
    <>
      <span className={styles.bold}>
        Sie haben ein externes Hausgeldkonto verknüpft, bitte beachten Sie:
      </span>
      <br />
      <span>
        Alle Banking-Funktionen, direkte Auszahlungen über unsere Plattform
        sowie die Anzeige des Kontostands stehen ausschließlich mit dem
        Getmomo-Hausgeldkonto zur Verfügung. Mit dem Getmomo-Kombiangebot nutzen
        Sie das Hausgeldkonto gemeinsam mit dem Rücklagenkonto und zahlen nur 8
        € monatlich. Bei Fragen beraten wir Sie gerne!
      </span>
    </>
  ) : (
    <>
      <span className={styles.bold}>
        Ihr Rücklagenkonto ist noch nicht aktiviert.
      </span>
      <br />
      <span>
        m Getmomo-Kombiangebot nutzen Sie es gemeinsam mit dem Hausgeldkonto,
        zahlen nur 8 € monatlich und sparen 2 €. Zusätzlich profitieren Sie von
        allen Banking-Funktionen und optimierten Auszahlungen.
      </span>
    </>
  );
}

function Basisangaben({condominium, onSave}) {
  const {
    register,
    reset,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const titleValue = watch('title', condominium?.title || '');

  const onSubmit = handleSubmit(async (fields) => {
    await updateCondominium({fields, condominium, setError, reset});
    onSave();
  });

  return (
    <div className={styles.basisangaben}>
      <div className={styles.BasisangabenContent}>
        <Input
          defaultValue={condominium?.title}
          label="Titel"
          maxLength={100}
          {...register('title')}
          error={errors.title?.message}
        />
        <div className={styles.counter}>
          {`${titleValue.length} / 100 Zeichen`}
        </div>
        <Input
          value={`${condominium?.streetName} ${condominium?.streetNumber}, ${condominium?.postalCode} ${condominium?.region}`}
          label="Adresse"
          readOnly
        />
      </div>
      <div className={styles.BasisangabenFooter}>
        <Button
          title="Speichern"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      </div>
    </div>
  );
}

async function updateCondominium({fields, condominium, setError, reset}) {
  return RPC('updateCondominium', {
    id: condominium.id,
    version: condominium.version,
    updateData: {
      title: fields.title,
    },
  })
    .then(() => {
      reset();
    })
    .catch((err) => {
      handleError(err);
      setError('title', {message: 'Fehler beim Speichern'});
    });
}
