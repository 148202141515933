import React from 'react';
import {useSelector} from 'react-redux';
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';
import {ReactComponent as ChevronDownSvg} from 'shared-web/components/chevron_down.svg';
import Icon from 'shared-web/components/Icon.js';

import {logout} from '../../actions.js';

import {ReactComponent as MomoAccountSvg} from './momo_account.svg';
import styles from './UserAccount.module.scss';

export default function UserAccount({minimized}) {
  const {email_address} = useSelector((state) => state.user.account);
  const history = useHistory();

  return (
    <Dropdown data-test="user-account">
      <Dropdown.Toggle as="button" className={styles.toggle}>
        {minimized ? (
          <OverlayTrigger
            key="right"
            placement="right"
            overlay={<Tooltip>Nutzerkonto</Tooltip>}>
            <Icon className={styles.account_icon}>
              <MomoAccountSvg />
            </Icon>
          </OverlayTrigger>
        ) : (
          <>
            <p
              className={classNames(
                styles.email_address,
                text_styles.body2_left,
              )}>
              {email_address}
            </p>
            <Icon className={styles.icon}>
              <ChevronDownSvg />
            </Icon>
          </>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item
          as="button"
          onClick={() => logout(history)}
          data-test="btn-submit">
          Abmelden
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
