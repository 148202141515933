import React from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, Link, useHistory, useParams} from 'react-router-dom';
import classNames from 'classnames/bind';
import TextInput from 'shared-web/components/TextInput.js';
import {BAD_REQUEST, UNAUTHORIZED} from 'shared/api.js';
import Button from 'shared-web/components/Button.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {MANAGER_PATHS} from 'shared/constants.js';

import {login} from '../../actions.js';
import {handleError, alert} from '../../effects.js';

import AuthenticationScreen from './AuthenticationScreen.js';
import styles from './LoginScreen.module.scss';

export default function LoginScreen() {
  const history = useHistory();
  const {search} = useLocation();
  const {deposit_id} = useParams();
  const token = search.slice(1);

  const {
    register,
    formState: {isSubmitting, isValid},
    handleSubmit,
  } = useForm({mode: 'onChange'});

  const {state: logout} = useLocation();

  const onSubmit = handleSubmit(({email_address, password}) =>
    proceed({email_address, password, deposit_id, token, history}),
  );

  return (
    <AuthenticationScreen>
      <div className={styles.content}>
        <p className={text_styles.h1_bright_bold_centered}>
          {logout
            ? 'Vielen Dank, Sie wurden erfolgreich abgemeldet'
            : 'Herzlich Willkommen bei Getmomo'}
        </p>
        <div className={styles.card}>
          <div className={styles.cardTop}>
            <p className={text_styles.body1_bold_centered}>
              {logout
                ? 'Bitte verwenden Sie Ihre Getmomo Zugangsdaten, wenn Sie sich wieder anmelden möchten.'
                : 'Bitte melden Sie sich mit Ihren Getmomo Zugangsdaten an.'}
            </p>
          </div>

          <form onSubmit={onSubmit}>
            <TextInput
              type="email"
              placeholder="E-Mail"
              className={classNames(styles.input, styles.input_email)}
              autoFocus={true}
              {...register('email_address', {required: true})}
              data-test="label-login-email"
            />

            <TextInput
              type="password"
              autoComplete="current-password"
              placeholder="Passwort"
              className={styles.input}
              {...register('password', {required: true})}
              data-test="label-login-password"
            />
            <div className={styles.button_container}>
              <Button
                type="submit"
                title="Jetzt anmelden"
                loading={isSubmitting}
                disabled={!isValid}
                className={styles.button}
                data-test="btn-submit"
              />
            </div>
          </form>
        </div>
        <div className={styles.links_container}>
          <Link
            to={{pathname: MANAGER_PATHS.RequestResetPasswordScreen}}
            className={classNames(
              text_styles.button_bold_bright,
              styles.link,
              styles.password_forgotten,
            )}>
            Passwort vergessen
          </Link>
          {token ? (
            <Link
              to={{
                pathname: `${MANAGER_PATHS.InvitationScreen}/${deposit_id}`,
                search: `?${token}`,
              }}
              className={classNames(
                text_styles.button_bold_bright,
                styles.link,
                styles.create_account,
              )}>
              Nutzerkonto erstellen
            </Link>
          ) : (
            <a
              href="https://www.getmomo.de/einfach-starten/"
              className={classNames(
                text_styles.button_bold_bright,
                styles.link,
                styles.create_account,
              )}>
              Nutzerkonto erstellen
            </a>
          )}
        </div>
      </div>
    </AuthenticationScreen>
  );
}

async function proceed({email_address, password, deposit_id, token, history}) {
  try {
    await login({
      email_address,
      password,
      deposit_id: token ? undefined : deposit_id,
      token,
      history,
    });
  } catch (err) {
    if (err.code === UNAUTHORIZED || err.code === BAD_REQUEST) {
      showCredentialError();
    } else {
      handleError(err);
    }
  }
}

function showCredentialError() {
  alert({
    text: 'Die E-Mail Adresse und das eingetragene Passwort stimmen nicht überein, bitte überprüfen Sie die Schreibweise oder kontaktieren Sie unseren Support.',
    label: 'OK',
    removeBackdrop: true,
    'data-test': 'alert-credentials-error',
  });
}
