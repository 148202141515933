import {useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {MANAGER_PATHS} from 'shared/constants.js';

import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import {useTrigger} from '../../hooks.js';

import AccountsTable from './AccountsTable.js';
import CondominiumAccountDialog from './CondominiumAccountDialog.js';
import DepositAccountDialog from './DepositAccountDialog.js';

export default function AccountsScreen() {
  const history = useHistory();

  const [search_query, setSearchQuery] = useState('');
  const [reset_table_trigger, doResetTable] = useTrigger();
  const [refresh, setRefresh] = useState(0);

  function routeToListScreen() {
    setRefresh(refresh + 1);
    history.push(MANAGER_PATHS.AccountsScreen);
  }

  return (
    <Layout
      button={<ButtonCreateAccount resetTable={doResetTable} />}
      search={<SearchInput onValue={setSearchQuery} />}>
      <Switch>
        <Route path={MANAGER_PATHS.CreateAccountDialog}>
          <div />
        </Route>
        <Route
          path={`${MANAGER_PATHS.AccountCondominiumDialog}/:first_account_id/:second_account_id?`}>
          <CondominiumAccountDialog onExited={routeToListScreen} />
        </Route>
        <Route
          path={`${MANAGER_PATHS.AccountDepositDialog}/:deposit_account_id`}>
          <DepositAccountDialog onExited={routeToListScreen} />
        </Route>
      </Switch>
      <AccountsTable
        search_query={search_query}
        reset_table_trigger={reset_table_trigger}
        refresh={refresh}
      />
    </Layout>
  );
}

function ButtonCreateAccount({resetTable}) {
  const history = useHistory();

  return (
    <ButtonAdd
      title="Konto hinzufügen"
      onClick={() => {
        resetTable();
        history.push(MANAGER_PATHS.CreateAccountDialog);
      }}
    />
  );
}
