import React, {useEffect, useRef} from 'react';
import {ReactDOM as ReactDOM18} from '@get-momo/ui';

export function React18Wrapper({children}) {
  const containerRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current || rootRef.current) return;

    try {
      rootRef.current = ReactDOM18.createRoot(containerRef.current);
      rootRef.current.render(children);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error creating or rendering React 18 root:', err);
      rootRef.current = null;
    }

    return () => {
      if (rootRef.current) {
        try {
          rootRef.current.unmount();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error unmounting React 18 root:', err);
        } finally {
          rootRef.current = null;
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  useEffect(() => {
    if (rootRef.current) {
      try {
        rootRef.current.render(children);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error rendering React 18 children:', err);
      }
    }
  }, [children]);

  return <div ref={containerRef} />;
}
