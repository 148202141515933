import React, {useMemo, useState} from 'react';
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import classNames from 'classnames';
import {
  formatDateShort,
  formatPrice,
  formatPriceNoCurrencySymbol,
  getClaimDetails,
  formatDate,
  downloadFile,
} from 'shared/utils.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {Icon} from '@get-momo/ui';
import {
  DEPOSIT_SUBSTATUS,
  DEPOSIT_TYPES,
  MANAGER_PATHS,
  TYPES,
} from 'shared/constants.js';
import {request} from 'shared/api.js';
import {useHistory} from 'react-router-dom';
import {useNotify} from 'shared-web/NotifyProvider.js';
import {useSelector} from 'react-redux';
import {useDebounce} from 'use-debounce';
import IconButton from 'shared-web/components/IconButton.js';

import {
  isClaimAllowed,
  isDeleteAllowed,
  isReleaseAllowed,
} from '../../lib/utils.js';
import {
  alert,
  showDialog,
  deleteDepositGuarantee,
  handleError,
  openTaxSummary,
} from '../../effects.js';
import {Table} from '../../components/Table/Table.js';
import {useTableContext} from '../../components/Table/context.js';
import Spinner from '../../components/spinner/Spinner.js';

import styles from './DepositsTable.module.scss';
import {ReactComponent as IconCashSvg} from './icon_cash.svg';
import {ReactComponent as IconGuaranteeSvg} from './icon_guarantee.svg';
import {ReactComponent as IconCalendarSvg} from './icon_calendar.svg';
import {ReactComponent as IconDownloadSvg} from './icon_download.svg';
import {useDepositFilters} from './hooks/useDepositFilters.js';
import {DepositStatusIcon} from './DepositStatusIcon.js';

const REFRESH_DELAY_MS = 2000;

const PLACEHOLDER_TEXT_NONE_CREATED = (
  <>
    Sie haben noch keine Mieter angelegt.
    <br />
    Sie können Ihren ersten Mieter per Klick auf „Mieter hinzufügen“ anlegen.
  </>
);

const PLACEHOLDER_TEXT_NO_MATCHES = (
  <>
    Leider konnten wir keine Ergebnisse finden.
    <br />
    Bitte überprüfen Sie Ihre Suchanfrage und Filtereinstellungen.
  </>
);

const DEFAULT_SORTING = {column: 'activity', direction: 'DESC'};

export default function DepositsTable({
  search_query,
  features,
  reset_table_trigger,
}) {
  const {refresh} = useSelector((state) => state.deposit_guarantees);
  const actions = useDepositsTableActions();
  const filters = useDepositFilters(features?.cash_deposit);
  const [debouncedRefresh] = useDebounce(refresh, REFRESH_DELAY_MS);

  return (
    <Table
      data_fetching_rpc_method_name="getDeposits"
      columns={columns}
      actions={actions}
      search_query={search_query}
      default_action="onOpen"
      refresh_trigger={debouncedRefresh}
      reset_trigger={reset_table_trigger}
      no_match_label={PLACEHOLDER_TEXT_NO_MATCHES}
      empty_table_label={PLACEHOLDER_TEXT_NONE_CREATED}
      default_sorting={DEFAULT_SORTING}
      filters={filters || []}
    />
  );
}

const columns = [
  {
    name: 'rental_unit',
    Header: 'Mieteinheit',
    Cell: RentalUnitCell,
    sort: [
      {
        column: 'tenant_last_name',
        direction: 'ASC',
        label: 'Name A-Z',
      },
      {
        column: 'tenant_last_name',
        direction: 'DESC',
        label: 'Name Z-A',
      },
      {
        column: 'given_reference',
        direction: 'ASC',
        label: 'Beschreibung aufsteigend',
      },
      {
        column: 'given_reference',
        direction: 'DESC',
        label: 'Beschreibung absteigend',
      },
    ],
    width: 'minmax(200px, 4fr)',
  },
  {
    name: 'address',
    Header: 'Adresse',
    Cell: AddressCell,
    sort: [
      {
        column: 'street_name',
        direction: 'ASC',
        label: 'Straße A-Z',
      },
      {
        column: 'street_name',
        direction: 'DESC',
        label: 'Straße Z-A',
      },
      {
        column: 'postal_code',
        direction: 'ASC',
        label: 'PLZ aufsteigend',
      },
      {
        column: 'postal_code',
        direction: 'DESC',
        label: 'PLZ absteigend',
      },
    ],
    width: 'minmax(200px, 2fr)',
  },
  {
    name: 'status',
    Header: 'Kaution',
    Cell: DepositStatusCell,
    width: 'max-content',
  },
  {
    name: 'deposit_details',
    Header: null,
    Cell: DepositDetailsCell,
    width: 'max-content',
  },
  {
    name: 'additional_details',
    Header: () => null,
    Cell: DepositFutureActivationCell,
    width: 'max-content',
  },
  {
    name: 'deposit_type',
    Header: 'Art',
    Cell: DepositTypeCell,
    width: 'max-content',
  },
  {
    name: 'created_at',
    Header: ({column}) => (column === 'activity' ? 'Aktivität' : 'Erstellt'),
    width: 'max-content',
    accessor: ({created_at}) => formatDateShort(created_at),
    justifyContent: 'right',
    sort: [
      {
        column: 'created_at',
        direction: 'DESC',
        label: 'Erstellt',
      },
      {
        column: 'activity',
        direction: 'DESC',
        label: 'Aktivität',
      },
    ],
  },
  {
    name: 'actions',
    Header: DownloadCsvButton,
    Cell: MenuCell,
    width: 'max-content',
    action: null,
  },
];

export function useDepositsTableActions() {
  const {notify} = useNotify();
  const history = useHistory();
  const actions = useMemo(
    () => ({
      onOpen: (deposit) => {
        deposit.deposit_type === DEPOSIT_TYPES.deposit_guarantee
          ? history.push(
              `${MANAGER_PATHS.DepositGuaranteeDialog}/${deposit.id}`,
            )
          : history.push(`${MANAGER_PATHS.CashDepositDialog}/${deposit.id}`);
      },
      onDelete: (deposit) =>
        deleteDepositGuarantee({deposit_guarantee: deposit, notify}),
      onRelease: (deposit) => onReleaseDepositGuarantee(deposit),
      onClaim: (deposit) => onRequestClaim(deposit),
      openTaxSummary: (deposit_id, year) => openTaxSummary(deposit_id, year),
      doPayout: (deposit) => {
        history.push(
          `${MANAGER_PATHS.InitiateCreditTransferDialog}/${deposit.id}`,
        );
      },
      showTransactions: (deposit) => {
        history.push(
          `${MANAGER_PATHS.CashDepositTransactionsDialog}/${deposit.id}`,
        );
      },
    }),
    [history, notify],
  );

  return actions;
}

function RentalUnitCell({value: row}) {
  const {
    tenant_first_name,
    tenant_last_name,
    type,
    number_of_tenants,
    given_reference,
    tenant_company_name,
  } = row;

  function renderTenantName() {
    if (tenant_company_name) {
      return (
        <span className={styles.tenant_display_name}>
          {tenant_company_name}
        </span>
      );
    }

    return (
      <>
        <span className={styles.tenant_display_name}>
          {tenant_last_name},&nbsp;{tenant_first_name}
        </span>
        {type === TYPES.individual && number_of_tenants > 1 && (
          <span>&nbsp;+1</span>
        )}
      </>
    );
  }

  function renderReference() {
    return (
      <span
        className={classNames(
          text_styles.body1_italic,
          styles.given_reference,
        )}>
        {given_reference || '–'}
      </span>
    );
  }

  return (
    <div className={styles.rental_unit}>
      <span
        className={classNames(
          text_styles.body1_bold_left,
          styles.tenants_names,
        )}>
        {renderTenantName()}
      </span>
      <br />
      {renderReference()}
    </div>
  );
}

function AddressCell({value: row}) {
  const {street_name, street_number, postal_code, region} = row;
  return street_name ? (
    <div className={styles.address}>
      <span>
        {street_name} {street_number},
      </span>
      <br />
      <span>
        {postal_code} {region}
      </span>
    </div>
  ) : (
    '–'
  );
}

function calculatePercentage({account_balance_cents, deposit_amount_cents}) {
  if (!deposit_amount_cents || deposit_amount_cents < 0) {
    return null;
  }
  return Math.floor(
    (Number(account_balance_cents) / deposit_amount_cents) * 100,
  );
}

function DepositStatusCell({value: row}) {
  const {status, deposit_amount_cents, account_balance_cents} = row;

  const show_check_icon = [
    DEPOSIT_SUBSTATUS.active,
    DEPOSIT_SUBSTATUS.active_in_future,
    DEPOSIT_SUBSTATUS.cash_deposit_paid_in_full,
  ].includes(status);

  const show_close_icon = [
    DEPOSIT_SUBSTATUS.rejected_by_tenant,
    DEPOSIT_SUBSTATUS.rejected_by_getmomo,
  ].includes(status);

  const show_percentage = [
    DEPOSIT_SUBSTATUS.cash_deposit_paid_in_part,
    DEPOSIT_SUBSTATUS.account_balance_empty,
  ].includes(status);

  const balance_percentage = calculatePercentage({
    account_balance_cents,
    deposit_amount_cents,
  });

  return (
    <div className={styles.status}>
      <DepositStatusIcon
        color={getStatusColor(status)}
        label={show_percentage ? `${balance_percentage}%` : undefined}
        show_check_icon={show_check_icon}
        show_close_icon={show_close_icon}
      />
    </div>
  );
}

function translateSubStatusNominator({
  status,
  claims,
  account_balance_cents,
  deposit_amount_cents,
}) {
  try {
    switch (status) {
      case DEPOSIT_SUBSTATUS.invitation_sent: {
        return 'Eingeladen';
      }
      case DEPOSIT_SUBSTATUS.video_identification_required: {
        return 'Video-Ident';
      }
      case DEPOSIT_SUBSTATUS.account_balance_empty:
      case DEPOSIT_SUBSTATUS.released_cash_deposit_with_balance:
      case DEPOSIT_SUBSTATUS.cash_deposit_with_outgoing_transactions:
      case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_part:
      case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_full: {
        return formatPriceNoCurrencySymbol(account_balance_cents ?? 0);
      }
      case DEPOSIT_SUBSTATUS.some_claims_paid_out:
      case DEPOSIT_SUBSTATUS.claim_requested:
      case DEPOSIT_SUBSTATUS.active_in_future:
      case DEPOSIT_SUBSTATUS.all_claims_paid_out:
      case DEPOSIT_SUBSTATUS.active: {
        const {total_paid_out_claims_cents} = getClaimDetails(claims);
        return formatPriceNoCurrencySymbol(
          deposit_amount_cents - total_paid_out_claims_cents,
        );
      }
      case DEPOSIT_SUBSTATUS.released: {
        return 'Aufgelöst';
      }
      case DEPOSIT_SUBSTATUS.rejected_by_tenant:
      case DEPOSIT_SUBSTATUS.rejected_by_getmomo:
      case DEPOSIT_SUBSTATUS.status_error: {
        return 'Abgelehnt';
      }
      case DEPOSIT_SUBSTATUS.disabled: {
        return 'Deaktiviert';
      }
      default: {
        return '';
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error translating sub-status nominator:', err);
    return '';
  }
}

function translateSubStatusDenominator({status, deposit_amount_cents}) {
  try {
    if (!status) return '';

    switch (status) {
      case DEPOSIT_SUBSTATUS.released:
      case DEPOSIT_SUBSTATUS.rejected_by_tenant:
      case DEPOSIT_SUBSTATUS.rejected_by_getmomo:
      case DEPOSIT_SUBSTATUS.status_error:
      case DEPOSIT_SUBSTATUS.disabled: {
        return '';
      }
      case DEPOSIT_SUBSTATUS.released_cash_deposit_with_balance: {
        return 'Aufgelöst';
      }
      default: {
        return formatPrice(deposit_amount_cents ?? 0);
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error translating sub-status denominator:', err);
    return '';
  }
}

function DepositDetailsCell({value: row}) {
  const {
    status,
    claims,
    deposit_guarantee_start_date,
    account_balance_cents,
    deposit_amount_cents,
  } = row;

  const nominator = translateSubStatusNominator({
    status,
    claims,
    deposit_guarantee_start_date,
    account_balance_cents,
    deposit_amount_cents,
  });

  const denominator = translateSubStatusDenominator({
    status,
    deposit_amount_cents,
  });

  return (
    <div className={styles.details}>
      <div className={styles.details_text}>
        <p className={classNames(text_styles.body1_bold_left, styles.status)}>
          {nominator}
        </p>
        {denominator && (
          <>
            &nbsp;/&nbsp;
            <p>{denominator}</p>
          </>
        )}
      </div>
    </div>
  );
}

function DepositFutureActivationCell({value: row}) {
  if (row.has_unsigned_transactions) {
    const one_day_in_milliseconds = 8.64e7;
    const time_difference =
      Date.now() - new Date(row.unsigned_transaction_created_at).getTime();
    const days_difference = Math.floor(
      time_difference / one_day_in_milliseconds,
    );

    const tooltipText =
      days_difference < 1
        ? 'EBICS ausstehend'
        : `EBICS – ausstehend seit ${days_difference} Tagen`;

    return (
      <OverlayTrigger
        key="right"
        placement="top"
        overlay={<Tooltip className={styles.tooltip}>{tooltipText}</Tooltip>}>
        <span style={{cursor: 'pointer'}}>
          <Icon name="ebicsSignature" />
        </span>
      </OverlayTrigger>
    );
  }

  if (row.status === DEPOSIT_SUBSTATUS.active_in_future) {
    return (
      <OverlayTrigger
        key="right"
        placement="top"
        overlay={
          <Tooltip>
            Aktiv ab {formatDate(row.deposit_guarantee_start_date)}
          </Tooltip>
        }>
        <span style={{cursor: 'pointer'}}>
          <IconCalendarSvg />
        </span>
      </OverlayTrigger>
    );
  }

  return null;
}

function DepositTypeCell({value: row}) {
  if (row.deposit_type === DEPOSIT_TYPES.cash_deposit) {
    return <IconCashSvg />;
  }
  if (row.deposit_type === DEPOSIT_TYPES.deposit_guarantee) {
    return <IconGuaranteeSvg />;
  }
}

function MenuCell({value: row}) {
  return (
    <Dropdown className={styles.dropdown}>
      <Dropdown.Toggle as="button">
        <Icon name="ellipsisVertical" />
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        {row.deposit_type === DEPOSIT_TYPES.cash_deposit ? (
          <CashDepositMenuItems deposit={row} />
        ) : (
          <DepositGuaranteeMenuItems deposit={row} />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DepositGuaranteeMenuItems({deposit}) {
  const {actions} = useTableContext();
  const {onDelete, onRelease, onClaim} = actions;

  return (
    <>
      <Dropdown.Item
        as="button"
        className={!isDeleteAllowed(deposit) && styles.disabled}
        onClick={() => onDelete(deposit)}>
        Mieter entfernen
      </Dropdown.Item>
      {!deposit.released_at && (
        <>
          <Dropdown.Item
            as="button"
            className={!isReleaseAllowed(deposit) && styles.disabled}
            onClick={() => onRelease(deposit)}>
            Kaution freigeben (Checkliste)
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            className={!isClaimAllowed(deposit) && styles.disabled}
            onClick={() => onClaim(deposit)}>
            Kaution einfordern
          </Dropdown.Item>
        </>
      )}
    </>
  );
}

function CashDepositMenuItems({deposit}) {
  const {actions} = useTableContext();
  const {doPayout, openTaxSummary, showTransactions} = actions;
  const viban_allocated_at_year = new Date(
    deposit.viban_allocated_at,
  ).getFullYear();
  const current_year = new Date().getFullYear();
  const current_month = new Date().getMonth();
  return (
    <>
      <Dropdown.Item as="button" onClick={() => doPayout(deposit)}>
        Auszahlung
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => showTransactions(deposit)}>
        Transaktionen
      </Dropdown.Item>
      {current_year !== viban_allocated_at_year && current_month === 0 ? (
        <>
          <Dropdown.Item
            as="button"
            onClick={() => openTaxSummary(deposit.id, current_year)}>
            Einzelaufstellung {current_year}
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            onClick={() => openTaxSummary(deposit.id, current_year - 1)}>
            Einzelaufstellung {current_year - 1}
          </Dropdown.Item>
        </>
      ) : (
        <Dropdown.Item
          as="button"
          onClick={() => openTaxSummary(deposit.id, current_year)}>
          Einzelaufstellung {current_year}
        </Dropdown.Item>
      )}
    </>
  );
}

function DownloadCsvButton() {
  const {sorting, search_query, filterQuery, data} = useTableContext();
  const [isLoading, setIsLoading] = useState(false);

  async function handleDownload() {
    setIsLoading(true);
    try {
      const params = {
        sort: sorting,
        q: search_query,
        filter: filterQuery || undefined,
      };

      const response = await request(`downloadDepositsCsv`, {
        method: 'POST',
        credentials: 'include',
        body: params,
      });

      const filename = `Getmomo_Mietkautionsbericht_${formatDate(
        new Date(),
      )}.csv`;

      await downloadFile(response, filename);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div>
        <Spinner size={24} />
      </div>
    );
  }
  const isDisabled = isLoading || data.length === 0;

  return (
    <IconButton disabled={isDisabled} onClick={handleDownload}>
      <IconDownloadSvg />
    </IconButton>
  );
}

function getStatusColor(status) {
  switch (status) {
    case DEPOSIT_SUBSTATUS.disabled:
    case DEPOSIT_SUBSTATUS.released:
    case DEPOSIT_SUBSTATUS.released_cash_deposit_with_balance:
    case DEPOSIT_SUBSTATUS.all_claims_paid_out: {
      return 'silver';
    }
    case DEPOSIT_SUBSTATUS.rejected_by_tenant:
    case DEPOSIT_SUBSTATUS.rejected_by_getmomo:
    case DEPOSIT_SUBSTATUS.status_error: {
      return 'red';
    }
    case DEPOSIT_SUBSTATUS.video_identification_required:
    case DEPOSIT_SUBSTATUS.invitation_sent:
    case DEPOSIT_SUBSTATUS.account_balance_empty:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_part: {
      return 'orange';
    }
    case DEPOSIT_SUBSTATUS.some_claims_paid_out:
    case DEPOSIT_SUBSTATUS.cash_deposit_with_outgoing_transactions:
    case DEPOSIT_SUBSTATUS.claim_requested: {
      return 'blue';
    }
    case DEPOSIT_SUBSTATUS.active:
    case DEPOSIT_SUBSTATUS.active_in_future:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_full: {
      return 'green';
    }
  }
}

function onRequestClaim(deposit) {
  if (!isClaimAllowed(deposit)) {
    return alert({
      title:
        'Sie können die Kaution erst einfordern wenn der Vertrag unterschrieben wurde und der Status „Aktiv“ angezeigt wird.',
    });
  }

  showDialog('claim', {
    deposit,
  });
}

function onReleaseDepositGuarantee(deposit) {
  if (!isReleaseAllowed(deposit)) {
    return alert({
      title:
        'Sie können die Kaution erst freigeben wenn der Vertrag unterschrieben wurde und der Status „Aktiv“ angezeigt wird.',
      text: 'Wenn Sie den noch nicht aktivierten Vertrag stornieren möchten, können Sie den Mieter entfernen.',
    });
  }

  showDialog('release', {
    deposit,
  });
}
